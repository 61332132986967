// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconUsers,
    IconUserCheck,
    IconBuildingWarehouse,
    IconKey,
    IconBrandCodesandbox,
    IconLetterCase,
    IconFileInfo,
    IconSatellite,
    IconFileUpload,
    IconUserExclamation,
    IconSettings,
    IconFileCertificate,
    IconBarcode,
    IconHierarchy,
    IconFiles
} from '@tabler/icons';
import { DashboardMenuProps } from './dashboard';

// constant
const icons = {
    IconUsers,
    IconUserCheck,
    IconBuildingWarehouse,
    IconKey,
    IconBrandCodesandbox,
    IconLetterCase,
    IconFileInfo,
    IconSatellite,
    IconFileUpload,
    IconUserExclamation,
    IconSettings,
    IconFileCertificate,
    IconBarcode,
    IconHierarchy,
    IconFiles
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application: DashboardMenuProps = {
    id: 'backoffice',
    title: <FormattedMessage id="backoffice" />,
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconUsers,
            url: 'backoffice/users'
        },
        {
            id: 'tenants',
            title: <FormattedMessage id="tenants" />,
            type: 'item',
            icon: icons.IconBuildingWarehouse,
            url: 'backoffice/tenants'
        },
        // {
        //     id: 'tenantUsers',
        //     title: <FormattedMessage id="tenantUsers" />,
        //     type: 'item',
        //     icon: icons.IconUserCheck,
        //     url: 'backoffice/tenant-users'
        // },
        {
            id: 'tenantTypes',
            title: <FormattedMessage id="tenantTypes" />,
            type: 'item',
            icon: icons.IconBrandCodesandbox,
            url: 'backoffice/tenant-types'
        },
        {
            id: 'roles',
            title: <FormattedMessage id="roles" />,
            type: 'item',
            icon: icons.IconKey,
            url: 'backoffice/roles'
        },
        {
            id: 'rolesAliases',
            title: <FormattedMessage id="roleAliases" />,
            type: 'item',
            icon: icons.IconLetterCase,
            url: 'backoffice/role-aliases'
        },
        {
            id: 'rolesByTenantType',
            title: <FormattedMessage id="rolesByTenantType" />,
            type: 'item',
            icon: icons.IconUserExclamation,
            url: 'backoffice/roles-by-tenant-types'
        },
        {
            id: 'configurationByRole',
            title: <FormattedMessage id="configurationByRole" />,
            type: 'item',
            icon: icons.IconSettings,
            url: 'backoffice/configuration-by-role'
        },
        {
            id: 'roleActions',
            title: <FormattedMessage id="roleActions" />,
            type: 'item',
            icon: icons.IconSettings,
            url: 'backoffice/role-actions'
        },
        {
            id: 'recordTypes',
            title: <FormattedMessage id="recordTypes" />,
            type: 'item',
            icon: icons.IconFileInfo,
            url: 'backoffice/record-types'
        },
        {
            id: 'recordsByTenantType',
            title: <FormattedMessage id="recordsByTenantType" />,
            type: 'item',
            icon: icons.IconFileCertificate,
            url: 'backoffice/record-by-tenant-types'
        },
        {
            id: 'recordsAditionalFields',
            title: <FormattedMessage id="recordsAditionalFields" />,
            type: 'item',
            icon: icons.IconFileUpload,
            url: 'backoffice/record-aditional-fields'
        },
        {
            id: 'coverageZones',
            title: <FormattedMessage id="coverageZones" />,
            type: 'item',
            icon: icons.IconSatellite,
            url: 'backoffice/coverage-zones'
        },
        {
            id: 'appModules',
            title: <FormattedMessage id="appModules" />,
            type: 'item',
            icon: icons.IconSatellite,
            url: 'backoffice/app-modules'
        },
        {
            id: 'problemCode',
            title: <FormattedMessage id="problemCode" />,
            type: 'item',
            icon: icons.IconBarcode,
            url: 'backoffice/problem-code'
        },
        {
            id: 'recordStatus',
            title: <FormattedMessage id="recordStatus" />,
            type: 'item',
            icon: icons.IconHierarchy,
            url: 'backoffice/record-status'
        },
        {
            id: 'tenantConfig',
            title: <FormattedMessage id="tenantConfig" />,
            type: 'item',
            icon: icons.IconSettings,
            url: '/backoffice/tenant-config'
        },
        {
            id: 'recordDetails',
            title: <FormattedMessage id="recordDetails" />,
            type: 'item',
            icon: icons.IconFiles,
            url: 'backoffice/record-details'
        }
    ]
};

export default application;
