// material-ui
// import { useTheme } from '@mui/material/styles';
// import logo from 'assets/images/avanto-logo.png';
//
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
const projectName = process.env.REACT_APP_PROJECT_NAME;

const logoUrl = projectName === 'POET' ? '/Poet_Primary_Logo.png' : '/OrderBahn_Logo_Vertical_RGB.png';
const altText = projectName === 'POET' ? 'Poet logo' : 'Avanto logo';

const Logo = () => <img src={logoUrl} alt={altText} style={{ margin: 'auto', width: '60%' }} />;

export default Logo;
