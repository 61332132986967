// project import
import dashboard from './dashboard';
import backoffice from './backoffice';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //
const isAdmin = localStorage.getItem('project admin') === 'true';

const menuItems: { items: NavItemType[] } = {
    items: isAdmin
        ? [dashboard, backoffice].map((item) => ({
              ...item,
              children: item.children?.filter(
                  (child: { id: string }) =>
                      child.id === 'users' ||
                      child.id === 'tenantTypes' ||
                      child.id === 'roles' ||
                      child.id === 'rolesAliases' ||
                      child.id === 'rolesByTenantType' ||
                      child.id === 'recordTypes' ||
                      child.id === 'recordsByTenantType' ||
                      child.id === 'recordsAditionalFields' ||
                      child.id === 'recordActions' ||
                      child.id === 'coverageZones' ||
                      child.id === 'problemCode' ||
                      child.id === 'recordStatus' ||
                      child.id === 'tenantConfig' ||
                      child.id === 'main'
              )
          }))
        : [dashboard, backoffice]
};

export default menuItems;
